import {Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureService} from '../services/feature.service';

@Directive({
  selector: '[msFeatureEnabled]',
  standalone: true,
})
export class FeatureEnabledDirective implements OnChanges {
  @Input('msFeatureEnabled')
  feature: string | string[];

  constructor(
    private readonly featureService: FeatureService,
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef
  ) {}

  private updateView(features: string[]): void {
    if (this.featureService.enabledAll(features)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('feature' in changes) {
      const features = typeof this.feature === 'string' ? this.feature.split(',') : this.feature;
      this.updateView(features);
    }
  }
}
