import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeatureService} from './services/feature.service';
import {
  FeatureDirective,
  FeatureFallbackDirective,
  FeatureToggleComponent,
} from './components/feature-toggle/feature-toggle.component';
import {FeatureEnabledDirective} from './directives/feature-enabled.directive';
import {FeatureDisabledDirective} from './directives/feature-disabled.directive';

@NgModule({
  imports: [
    CommonModule,
    FeatureToggleComponent,
    FeatureDirective,
    FeatureFallbackDirective,
    FeatureDisabledDirective,
    FeatureEnabledDirective,
  ],
  exports: [
    FeatureToggleComponent,
    FeatureDirective,
    FeatureFallbackDirective,
    FeatureDisabledDirective,
    FeatureEnabledDirective,
  ],
})
export class FeatureToggleModule {
  static forRoot(): ModuleWithProviders<FeatureToggleModule> {
    return {
      ngModule: FeatureToggleModule,
      providers: [FeatureService],
    };
  }
}
